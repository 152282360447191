import React from "react";
import { createRoot } from 'react-dom/client';

// import "/imports/client/errorLogging";
// import "/imports/client/services/translations/i18n";

// import "/imports/startup/both";

// import AppRoutes from "/imports/client/router/AppRoutes";

import App from "/startup/App";


const container = document.getElementById('react-root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(<App />);
