import getCookie from "./getCookie";

const debug = require("debug")("client-keys");

interface IReactClientKeys {
  REACT_APP_SENTRY?: string;
  REACT_APP_AG_GRID?: string;
  REACT_APP_MAPS_API?: string;
  REACT_APP_HERE_KEY?: string;
  REACT_APP_SERVER_NAME?: string;
  REACT_APP_RELEASE_NAME?: string;
  REACT_APP_WS_URL?: string;
  REACT_APP_API_URL?: string;
  REACT_CRISP_WEBSITE_ID?: string;
  REACT_APP_TURNSTILE_SITE_KEY?: string
}

let KEYS: IReactClientKeys = {};
try {
  KEYS = {
    REACT_APP_SENTRY: process.env.REACT_APP_SENTRY,
    REACT_APP_AG_GRID: process.env.REACT_APP_AG_GRID,
    REACT_APP_MAPS_API: process.env.REACT_APP_MAPS_API,
    REACT_APP_HERE_KEY: process.env.REACT_APP_HERE_KEY,
    REACT_APP_SERVER_NAME: process.env.REACT_APP_SERVER_NAME || window.location.host,
    REACT_APP_RELEASE_NAME: process.env.REACT_APP_RELEASE_NAME,
    REACT_APP_WS_URL: process.env.REACT_APP_WS_URL,
    REACT_APP_API_URL: process.env.REACT_APP_API_URL,
    REACT_CRISP_WEBSITE_ID: "0d5ead40-2816-4550-8aba-1d2231444add",
    REACT_APP_TURNSTILE_SITE_KEY: process.env.REACT_APP_TURNSTILE_SITE_KEY|| '1x00000000000000000000AA'
  };
} catch (e) {
  debug("no process.env vars");
}

// todo replace this file with fixed vars on production
const getReactAppKeys = () => {
  const appKeys = getCookie("clientKeys");

  const keys = appKeys ? JSON.parse(decodeURIComponent(appKeys)) : KEYS;

  debug("keys %o", keys);
  if (window.location.host.startsWith("localhost")) {
    debug("localhost, no need to set graphql api");
  } else if (!keys.REACT_APP_WS_URL && window.location.host.includes(".s1.transmate.eu")) {
    keys.REACT_APP_WS_URL = `wss://${window.location.host.replace(".s1", "")}/graphql`;
    keys.REACT_APP_API_URL = `https://${window.location.host.replace(".s1", "")}/graphql`;
    debug("set api to backend server: %s", keys.REACT_APP_WS_URL);
  }
  return keys;
};

export default getReactAppKeys;
