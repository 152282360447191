import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// eslint-disable-next-line import/no-namespace
import LanguageDetector from "i18next-browser-languagedetector";

//en
import common from "/imports/utils/locales/en/translation.en.i18n.json";
import tenderify from "/imports/utils/locales/en/tenderify.en.i18n.json";
import shipmentProject from "/imports/utils/locales/en/shipmentProject.en.i18n.json";
import priceList from "/imports/utils/locales/en/priceList.en.i18n.json";
import invoice from "/imports/utils/locales/en/invoice.en.i18n.json";
import tender from "/imports/utils/locales/en/tender.en.i18n.json";
import priceRequest from "/imports/utils/locales/en/priceRequest.en.i18n.json";
import shipment from "/imports/utils/locales/en/shipment.en.i18n.json";
import partner from "/imports/utils/locales/en/partner.en.i18n.json";

// de
import de18common from "/imports/utils/locales/de/translation.de.i18n.json";
import de18tenderify from "/imports/utils/locales/de/tenderify.de.i18n.json";
import de18shipmentProject from "/imports/utils/locales/de/shipmentProject.de.i18n.json";
import de18priceList from "/imports/utils/locales/de/priceList.de.i18n.json";
import de18invoice from "/imports/utils/locales/de/invoice.de.i18n.json";
import de18tender from "/imports/utils/locales/de/tender.de.i18n.json";
import de18priceRequest from "/imports/utils/locales/de/priceRequest.de.i18n.json";
import de18shipment from "/imports/utils/locales/de/shipment.de.i18n.json";
import de18partner from "/imports/utils/locales/de/partner.de.i18n.json";

import client from "/imports/client/services/apollo/client";
import { LOG_MISSING_TRANSLATION } from "./query";

const debug = require("debug")("translations");

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: "en",
    ns: [
      "common",
      "tenderify",
      "invoice",
      "priceList",
      "shipmentProject",
      "tender",
      "priceRequest",
      "shipment",
      "partner"
    ],
    defaultNS: "common",
    debug: false,
    lng: "en",
    resources: {
      en: {
        common,
        tenderify,
        invoice,
        priceList,
        shipmentProject,
        tender,
        priceRequest,
        shipment,
        partner
      },
      de: {
        common: de18common,
        tenderify: de18tenderify,
        invoice: de18invoice,
        priceList: de18priceList,
        shipmentProject: de18shipmentProject,
        tender: de18tender,
        priceRequest: de18priceRequest,
        shipment: de18shipment,
        partner: de18partner
      }
    },

    keySeparator: ".", // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    react: {
      bindI18n: "languageChanged",
      bindI18nStore: "",
      transEmptyNodeValue: "",
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ["b", "br", "strong", "i"],
      useSuspense: true
    },
    saveMissing: true,

    // Method triggered client-side when a token is missing
    // It will still respect your fallback language,
    // but let you add a side-effect like calling Sentry
    missingKeyHandler: (
      ngs,
      ns,
      key,
      fallbackValue,
      updateMissing,
      options
    ) => {
      debug("missing key", { ns, key });

      client.mutate({
        mutation: LOG_MISSING_TRANSLATION,
        variables: {
          input: {
            key,
            ns,
            ngs: ngs.join(","),
            path: window.location.href
          }
        }
      });
    }

    // I don't get it working:
    // backend: {
    //   // for all available options read the backend's repository readme file
    //   loadPath: "/i18n/{{ns}}.{{lng}}.i18n.json"
    // }
  });

export default i18n;
