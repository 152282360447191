const KEY_USERID = "KEY_USERID";
const KEY_SESSION_USER = "KEY_SESSION_USER";
const KEY_AUTH_TOKEN = "KEY_AUTH_TOKEN";
const KEY_API_URL = "KEY_API_URL";

export const setSessionUserId = (userId: string) => {
  if (!userId) return localStorage.removeItem(KEY_USERID);
  localStorage.setItem(KEY_USERID, userId);
};

export const getSessionUserId = () => localStorage.getItem(KEY_USERID);

export const getSessionApiUrl = () => localStorage.getItem(KEY_API_URL); // TM_apiServer in header

export const setSessionApiUrl = (url:string) => {
  if (!url) return localStorage.setItem(KEY_API_URL, "reset");
  return localStorage.setItem(KEY_API_URL, url);
};

export const setSessionUser = (user: any) => {
  if (!user) return localStorage.removeItem(KEY_SESSION_USER);
  return localStorage.setItem(KEY_SESSION_USER, JSON.stringify(user));
};

export const getSessionUser = () => {
  const str = localStorage.getItem(KEY_SESSION_USER);

  try {
    return str ? JSON.parse(str) : null;
  } catch (err) {
    return null;
  }
};

export const setSessionToken = (token: string) => {
  if (!token) return localStorage.removeItem(KEY_AUTH_TOKEN);
  return localStorage.setItem(KEY_AUTH_TOKEN, token);
};

export const getSessionToken = () => localStorage.getItem(KEY_AUTH_TOKEN);

export const clearSessionData = () => {
  setSessionToken(null);
  setSessionUser(null);
  setSessionUserId(null);
  setSessionApiUrl(null);
};

export const setSessionLoginData = (args: {
  token: string;
  userId: string;
  user: any;
}) => {
  const userData = { ...args.user, id: args.userId };
  setSessionToken(args.token);
  setSessionUser(userData);
  setSessionUserId(args.userId);
};
