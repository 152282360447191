import { lazy, Suspense } from 'react';

import "./main.less";

import { Sentry } from  "/imports/client/errorLogging";
import "/imports/client/services/translations/i18n";
import Loader from "/imports/client/components/utilities/Loader";

// import AppRoutes from "/imports/client/router/AppRoutes";
const AppRoutes = lazy(() => import("/imports/client/router/AppRoutes"));

function FallbackComponent() {
  return (
    <div>An error has occured</div>
  )
}

function App() {

  return (
    <Sentry.ErrorBoundary fallback={FallbackComponent} showDialog>
      <Suspense fallback={<Loader loading />}>
        <AppRoutes></AppRoutes>
      </Suspense>
    </Sentry.ErrorBoundary>
  );

}
export default Sentry.withProfiler(App);
//export default App;
