import {
  ApolloClient, ApolloLink, split, InMemoryCache, HttpLink
} from "@apollo/client";
import { SentryLink } from "apollo-link-sentry";
import { getMainDefinition } from "@apollo/client/utilities";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import getReactAppKeys from "/imports/client/utils/getReactAppKeys";
import authLink, { getHeaders } from "./auth-link";
import { setSessionApiUrl } from "/imports/api/accounts/client/user-session";

const clientKeys = getReactAppKeys();

const debug = require("debug")("gql:client");

const afterwareLink = new ApolloLink((operation, forward) => forward(operation).map((response) => {
  const context = operation.getContext();
  const apiServer = context.response.headers.get("TM_apiServer");

  // debug("store api server url %o", apiServer);
  setSessionApiUrl(apiServer);
  return response;
}));

const clientOptions = {
  cache: new InMemoryCache({
    typePolicies: {
      AnalysisSimulation: {
        keyFields: ["analysisId"]
      },
      TenderBiddersType: {
        keyFields: ["accountId"]
      },
      TenderBidDataLine: {
        keyFields: ["lineId", "tenderBidId"]
      },
      User: {
        fields: {
          preferences: {
            merge: true
          }
        }
      },
      TenderContactsType: {
        keyFields: ["userId"]
      },
      UnreadCommentsForDocumentResponse:{
        keyFields: ["documentType", "documentId"]
      }
    }
  }),
  name: "transmate-react-client",
  version: "1.1",
  defaultOptions: {
    query: {
      fetchPolicy: "cache-and-network",
      errorPolicy: "all"
    },
    mutate: {
      errorPolicy: "all"
    },
    watchQuery: {
      fetchPolicy: "cache-and-network"
    }
  },
  connectToDevTools: true
};

const hasSubscriptionOperation = ({ query }) => {
  const definition = getMainDefinition(query);
  return (
    definition.kind === "OperationDefinition"
    && definition.operation === "subscription"
  );
};

// : dynamic
const { REACT_APP_WS_URL, REACT_APP_API_URL } = clientKeys;

debug("we are running  api on %o %o", REACT_APP_WS_URL, REACT_APP_API_URL);

const wsURI = REACT_APP_WS_URL
  || `${(window.location.protocol === "https:" ? "wss://" : "ws://") + window.location.host}/graphql`;

const wsLink = new GraphQLWsLink(
  createClient({
    url: wsURI,
    connectionParams() {
      const headers = getHeaders();

      return headers;
    },
    timeout: 30000,
    reconnect: true
  })
);

const apiURI = REACT_APP_API_URL || "/graphql";

const links = [
  authLink,
  new SentryLink(/* See options */),
  afterwareLink,
  new HttpLink({
    uri: apiURI
  } // at the end

    // batch links have problem
    // new BatchHttpLink({
    //   uri: "/graphql",
    //   batchMax: 5, // No more than 5 operations per batch
    //   batchInterval: 50 // Wait no more than 20ms after first batched operation
    // }
  )
];

const link = ApolloLink.from(links);

const rootLink = split(hasSubscriptionOperation, wsLink, link);

const client = new ApolloClient({
  link: rootLink,
  ...clientOptions
});

export default client;
